<template>
	<div class='app-container'>
		<dd-card class="box-card" :title="'Filtre'" :collapsed.sync="isCollapsed"
			@toggledCard="isCollapsed = !isCollapsed">
			<el-row :gutter="10">
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">De la </div>
					<el-date-picker class="fullWidth" v-model="filters.from.value" value-format="yyyy-MM-dd" type="date"
						@change="applyFilters">
					</el-date-picker>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Pana la </div>
					<el-date-picker class="fullWidth" v-model="filters.to.value" value-format="yyyy-MM-dd" type="date"
						@change="applyFilters">
					</el-date-picker>
				</el-col>
			</el-row>
		</dd-card>
		<div class="separator"></div>
		<el-card class='box-card not-billed-com'>
			<div class="body">
				<div class="total-payments-to-aff">Total incasari transferate catre Partener MP: {{this.total}} RON
				</div>
				<el-collapse v-model="activeItem" accordion>
					<el-collapse-item :title="parseGroupDate(date)" :name="date" v-for="(items, date) in list"
						:key="date">
						<el-table size="mini" border :data="items" style="width: 100%">
							<el-table-column prop="date" label="Data">
							</el-table-column>
							<el-table-column prop="total" label="Suma">
							</el-table-column>
						</el-table>
					</el-collapse-item>
				</el-collapse>
			</div>
		</el-card>
	</div>
</template>
<script>
	import {
		getPaymentsToAffiliate
	} from '@/api/plati';

	const DDCard = () => import('@/components/DDCard');
	const Box = () => import('vue-info-box-element');
	import capitalize from 'lodash.capitalize';

	export default {
		components: {
			'dd-card': DDCard,
			Box
		},
		props: ['filterQuery'],
		data() {
			return {
				activeItem: '',
				isCollapsed: true,
				filters: {
					from: {
						type: 'input',
						value: ''
					},
					to: {
						type: 'input',
						value: ''
					}
				},
				list: {},
				total: 0
			};
		},
		methods: {
			async getPayments() {
				try {
					const params = {};
					if (this.filters.from.value !== '' && this.filters.from.value != null) {
						params.from = this.filters.from.value;
					}
					if (this.filters.to.value !== '' && this.filters.to.value != null) {
						params.to = this.filters.to.value;
					}
					const res = await getPaymentsToAffiliate(params);
					this.list = res.message.list;
					if (res.message.total != null) this.total = res.message.total;
				} catch (err) {}
			},
			applyFilters() {
				if (typeof this.updateQuery === 'function') this.updateQuery();
				this.getPayments();
			},
			parseGroupDate(date) {
				return capitalize(this.$moment(date, 'YYYYMM').format('MMMM Y'));
			}
		},
		computed: {},
		mounted() {
			this.getPayments();
		}
	};
</script>
<style rel='stylesheet/scss' lang='scss' scoped>
	.total-payments-to-aff {
		font-weight: bold;
		padding: 0 0 10px 0;
	}
</style>